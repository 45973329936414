import * as React from 'react'
import Layout from '../components/layout'
import { BLink } from '../components/content-components'
import { StaticImage } from 'gatsby-plugin-image'

const NotFoundPage = () => {
  return (
    <Layout pageTitle="404 Not Found">
      <div className="text-center">
        <StaticImage alt="Wugs in a maze" src="../images/wug_in_a_maze.png" />
      </div>
      <div className="text-center mb-5">
        It appears that you have arrived at a dead link.
      </div>
      <div className="text-center">
        Go back to <BLink className="font-bold" to="/">home</BLink>.
      </div>
    </Layout>
  )
}

export default NotFoundPage